const config = {
    photoAnimationDuration: 1, // seconds
    titleAnimationDuration: 1, // seconds
    dragThreshold: 100,
    storyDuration: 5000, // milisseconds
    scrollLockTime: 750, // milisseconds
    allowOnDemandPurchase: process.env.ALLOW_ON_DEMAND_PURCHASE
        ? JSON.parse(process.env.ALLOW_ON_DEMAND_PURCHASE)
        : false,
    apiPath: {
        development: `http://${process.env.API_IP || 'localhost'}:${process.env.API_PORT || 3000}`,
        production: 'https://api.site.dojo.do',
    },
    showPrices: false,
};

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
    window.location.search.slice(1).split('&').forEach((param) => {
        const [paramName, paramValue] = param.split('=');
        config[paramName] = paramValue;
    });
}

export default config;
