const SET_PAGE = 'SET_PAGE';
function setPage(page, data) {
    return {
        type: SET_PAGE,
        page,
        data,
    };
}

export {
    SET_PAGE,
    setPage,
};
