// SIMPLE ACTIONS - sync actions with no parameters
export * from './simpleActions';

// REGULAR ACTIONS - sync actions with parameters
export {
    SET_DIMENSIONS,
    setDimensions,
} from './setDimensions';

export {
    SET_ITEMS,
    setItems,
} from './setItems';

export {
    ADD_TO_CART,
    addToCart,
} from './addToCart';

export {
    REMOVE_FROM_CART,
    removeFromCart,
} from './removeFromCart';

export {
    SET_IS_MOBILE,
    setIsMobile,
} from './setIsMobile';

export {
    SET_PAGE,
    setPage,
} from './setPage';

// THUNKS - async actions
export { scrollTo } from './thunks/scrollTo';
export { loadItems } from './thunks/loadItems';
export { doPurchase } from './thunks/doPurchase';
export { loadPage } from './thunks/loadPage';
