import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    number,
    bool,
    func,
    object,
} from 'prop-types';
import RandomImage from '../RandomImage';
import { routes } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    width: number.isRequired,
    isMobile: bool.isRequired,
    loadPage: func.isRequired,
    page: object,
};

class Header extends Component {
    constructor(props) {
        super(props);

        this.dragStartX = null;

        this.state = {
            titlePosition: 50,
        };

        this.adjustTitlePosition = this.adjustTitlePosition.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
    }

    componentDidMount() {
        const { loadPage, page } = this.props;
        if (!page) {
            loadPage();
        }
    }

    onTouchStart(e) {
        this.dragStartX = e.touches[0].pageX;
    }

    onTouchMove(e) {
        if (this.dragStartX === null) {
            return;
        }

        let { titlePosition } = this.state;
        const touchPos = e.touches[0].pageX;
        titlePosition -= touchPos - this.dragStartX;
        this.dragStartX = touchPos;
        this.setState({ titlePosition });
    }

    onTouchEnd() {
        this.dragStartX = null;
    }

    adjustTitlePosition(e) {
        const { width } = this.props;

        this.setState({ titlePosition: 300 - 600 * e.pageX / width });
    }

    mainImage() {
        const { page, isMobile } = this.props;
        if (page) {
            return [{
                1: isMobile ? page.data.image_url : page.data.image_url,
                2: isMobile ? page.data.image_url : page.data.image_url,
                3: isMobile ? page.data.image_url : page.data.image_url,
            }];
        }
        return [];
    }

    render() {
        const { titlePosition } = this.state;
        const mainImage = this.mainImage();

        const titleStyle = {
            backgroundPosition: `${titlePosition}% 50%`,
        };

        return (
            <div
                id="header"
                className={styles.header}
                onMouseMove={this.adjustTitlePosition}
                onTouchStart={this.onTouchStart}
                onTouchMove={this.onTouchMove}
                onTouchEnd={this.onTouchEnd}
            >
                {mainImage.length > 0 && (
                    <RandomImage
                        images={mainImage}
                        className={styles.image}
                    />
                )}
                <div className={styles.dojoTitle} style={titleStyle} />
                <Link to={routes.collection()} className={styles.collectionLink}>
                    Sobre a Coleção
                </Link>
            </div>
        );
    }
}

Header.propTypes = propTypes;

Header.defaultProps = {
    page: null,
};

export default Header;
