import { SET_PAGE } from '../actions';

const initialState = {
    home: null,
    about: null,
};

function page(state = initialState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case SET_PAGE:
            newState[action.page] = action.data || null;
            break;

        default:
            newState = state;
    }

    return newState;
}

export default page;
