import React, { Component } from 'react';
import { bool, object, func } from 'prop-types';
import Carousel from '../../components/Carousel';
import RandomImage from '../../components/RandomImage';

import styles from './styles.scss';

const propTypes = {
    loadPage: func.isRequired,
    isMobile: bool.isRequired,
    page: object,
};

class Collection extends Component {
    componentDidMount() {
        const { page, loadPage } = this.props;

        if (!page) {
            loadPage();
        }

        this.body = this.body.bind(this);
        this.images = this.images.bind(this);
        this.mainImage = this.mainImage.bind(this);
    }

    body() {
        const { page } = this.props;
        if (page) return page.data.text;
        return '';
    }

    images() {
        const { page, isMobile } = this.props;
        if (page) {
            return (page.data.gallery || []).map(item => ({
                1: isMobile ? item.url : item.url,
                2: isMobile ? item.url : item.url,
                3: isMobile ? item.url : item.url,
            }));
        }
        return [];
    }

    mainImage() {
        const { page, isMobile } = this.props;
        if (page) {
            return [{
                1: isMobile ? page.data.image_url : page.data.image_url,
                2: isMobile ? page.data.image_url : page.data.image_url,
                3: isMobile ? page.data.image_url : page.data.image_url,
            }];
        }
        return [];
    }

    render() {
        const mainImage = this.mainImage();
        const images = this.images();
        return (
            <div className={styles.collection}>
                <div className={styles.content}>
                    <div className={styles.info}>
                        <div className={styles.title}>
                            Sobre a Coleção
                        </div>
                        <div
                            className={styles.description}
                            dangerouslySetInnerHTML={{
                                __html: this.body(),
                            }}
                        />
                    </div>
                    {mainImage.length > 0 && (
                        <RandomImage images={mainImage} className={styles.roulette} />
                    )}
                </div>
                {images.length > 0 && (
                    <Carousel
                        className={styles.carousel}
                        photos={images}
                    />
                )}
            </div>
        );
    }
}

Collection.propTypes = propTypes;

Collection.defaultProps = {
    page: null,
};

export default Collection;
