import { connect } from 'react-redux';
import Collection from './Collection';
import { loadPage as loadPageAction } from '../../../redux/actions';

const mapStateToProps = ({ isMobile, page }) => ({
    isMobile,
    page: page.about,
});

const mapDispatchToProps = (dispatch) => {
    const loadPage = () => dispatch(loadPageAction('about'));

    return { loadPage };
};

const CollectionContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Collection);

export default CollectionContainer;
