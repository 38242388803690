import { connect } from 'react-redux';
import Header from './Header';
import {
    scrollTo as scrollToAction,
    loadPage as loadPageAction,
} from '../../../redux/actions';

const mapStateToProps = ({ width, isMobile, page }) => ({
    width,
    isMobile,
    page: page.home,
});

const mapDispatchToProps = (dispatch) => {
    const scrollTo = target => dispatch(scrollToAction(target));
    const loadPage = () => dispatch(loadPageAction('home'));

    return { scrollTo, loadPage };
};

const HeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Header);

export default HeaderContainer;
