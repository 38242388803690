import { setPage } from '../setPage';
import { StoreService } from '../../../services';

function loadPage(slug) {
    return dispatch => (
        StoreService.loadPage(slug)
            .then((result) => {
                dispatch(setPage(slug, result.data));
                return result.data;
            })
    );
}

export { loadPage };
