import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { object, func } from 'prop-types';

import CheckoutPage from '../../components/CheckoutPage';
import LoadingSpinner from '../../components/LoadingSpinner';
import { routes } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    cart: object.isRequired,
    doPurchase: func.isRequired,
    clearCart: func.isRequired,
};

class Checkout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            success: false,
            error: null,
        };

        this.setError = this.setError.bind(this);
    }

    componentDidMount() {
        this.doCheckout();
    }

    setField(field, value) {
        this.setState({ [field]: value });
    }

    setError(error = null) {
        this.setState({ error });
    }

    setSuccess(success = false) {
        this.setState({ success });
    }

    doCheckout() {
        const {
            doPurchase,
            cart,
            clearCart,
        } = this.props;

        this.setSuccess(false);
        this.setError(null);

        const products = Object.values(cart)
            .reduce((acc, item) => {
                acc.push({ sku: item.sku, quantity: item.count });
                return acc;
            }, []);

        if (products.length > 0) {
            const payload = { products };

            doPurchase(payload).then((result) => {
                if (result.success) {
                    this.setSuccess(true);
                    clearCart();
                    document.location.href = result.message;
                } else {
                    this.setError(result.message);
                }
            });
        }
    }

    render() {
        const { cart } = this.props;
        const { success, error } = this.state;
        const cartCount = Object.values(cart).length;

        if (!success && cartCount === 0) {
            return <Redirect to={routes.cart()} replace />;
        }

        return (
            <CheckoutPage title="" key="form">
                {error && (
                    <div className={styles.error}>
                        {error}
                    </div>
                )}
                {!error && (
                    <LoadingSpinner className={styles.loadingSpinner} />
                )}
            </CheckoutPage>
        );
    }
}

Checkout.propTypes = propTypes;

export default Checkout;
