import BaseAPI from './BaseApi';

const StoreService = {
    ...BaseAPI,

    loadItems() {
        return this.doGETRequest('/store-products/');
    },

    doPurchase(payload) {
        return this.doPOSTRequest('/store-checkout/', JSON.stringify(payload));
    },

    loadPage(slug) {
        return this.doGETRequest(`/store-pages/${slug}`);
    },
};

export default StoreService;
